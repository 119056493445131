import React from "react";
import Layout from "../components/Layout";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Style } from "../components/Leistungen/styled";

export default function Leistungen({
  data: { grass, lawnMower, wildFlowers },
  location,
}) {
  return (
    <Layout
      location={location}
      title={"Gartenbau Dannenfelser | Leistungen"}
      description={
        "Gartenplanung und Gestaltung, Pflasterarbeiten, Verlegung von Rollrasen, Baumpflege und Fällungen, Gehölzschnitt, Unterhaltungspflege, Erstellen von Zäunen und Sichtschutzwänden aus Beton, Metall oder Holz, Bau von Natur- und Betonmauern, Naturnahe Gärten. "
      }
    >
      <Style>
        <div className={"images"}>
          <div className={"image-wrapper image-1"}>
            <GatsbyImage
              image={getImage(lawnMower)}
              alt={"Ein Rasenmäher, fotografiert aus der Froschperspektive"}
            />
          </div>

          <div className={"image-wrapper image-2"}>
            <GatsbyImage
              image={getImage(grass)}
              alt={"Pflanzen blühen in einem gepflegten Beet"}
            />
          </div>

          <div className={"image-wrapper image-3"}>
            <GatsbyImage
              image={getImage(wildFlowers)}
              alt={"Wildblumen blühen auf einer Wiese"}
            />
          </div>
        </div>

        <div className={"services"}>
          <div className={"a x"}> Gartenplanung &amp; - gestaltung</div>
          <div className={"b y"}>Naturnahe Gärten</div>
          <div className={"a y"}>Pflasterarbeiten</div>
          <div className={"b x"}>Bau von Natur- und Betonmauern</div>
          <div className={"a x"}>
            Erstellen von Zäunen und Sichtschutzwänden aus Beton, Metall oder
            Holz
          </div>
          <div className={"b y"}>Verlegung von Rollrasen</div>
          <div className={"a y"}>Baumpflege &amp; -fällungen</div>
          <div className={"b x"}>Gehölzschnitt</div>
          <div className={"a x"}>Unterhaltungspflege</div>
        </div>
      </Style>
    </Layout>
  );
}

export const pageQuery = graphql`
  query {
    grass: file(relativePath: { eq: "grass.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          height: 200
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }

    lawnMower: file(relativePath: { eq: "lawnMower.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          height: 200
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }

    wildFlowers: file(relativePath: { eq: "wildFlowers.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          height: 200
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`;
